import { template as template_e788047a573f4543939098f7c7910c81 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e788047a573f4543939098f7c7910c81(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
