import { template as template_e269a82f00aa4a028e00c7eca5f0e969 } from "@ember/template-compiler";
const WelcomeHeader = template_e269a82f00aa4a028e00c7eca5f0e969(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
