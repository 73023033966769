import { template as template_8906bc883a0448ee923ae9b42182f1a9 } from "@ember/template-compiler";
const FKLabel = template_8906bc883a0448ee923ae9b42182f1a9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
